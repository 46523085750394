import React from "react";
import PageLayout from "../layouts/PageLayout";
import SpacingContent from "../../components/SpacingContent";
import Section from "../../components/Section";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";

// => Images
import g1 from "../../assets/media/images/gallery/g1.jpg";
import g2 from "../../assets/media/images/gallery/g4.jpg";
import g3 from "../../assets/media/images/gallery/g7.JPG";

const SeminaryLifePage = () => {
    return (
        <PageLayout
            pageHead={"Seminary life"}
            pageSubHead={
                "Certainly! Life in the seminary revolves around three core pillars: study, prayer, and service.🙏✝️"
            }
        >
            <SpacingContent maxSpace={11}>
                <Grid container sx={{ mt: 10 }} columnSpacing={4}>
                    <Grid item sm={7} xs={12}>
                        <Box>
                            <Section
                                head={"A life of study"}
                                subHead={
                                    "Since a priest as a pastor is a teacher, a prophet and a counsellor for his people, the studies in the seminary must foster thinking, and instill a basic understanding of Crist's message. To study is the seminarians' main duty of state. He must fulfil it faithfully and untiringly."
                                }
                            >
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        lineHeight: 1.6,
                                        opacity: 0.8,
                                    }}
                                >
                                    As regards this life of study, seminary
                                    formation includes studies. As expressed in
                                    the “Optatam Totius” n. 13-18, on the
                                    revision of the ecclesiastical studies all
                                    the seminarians have to follow the school
                                    curricular which is organized according to
                                    this part of “Optatam totius”. Seminarians
                                    therefore, shall attend all lectures except
                                    when there is a reasonable cause for not
                                    doing so. Personal study should also be a
                                    part of seminarian life, as a personal
                                    effort to understand the ecclesiastical
                                    studies. Assignments given shall be done
                                    seriously and within the time limit
                                    allocated for them.
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        lineHeight: 1.6,
                                        opacity: 0.8,
                                        mt: 4,
                                    }}
                                >
                                    “Keep before you an outline of the sound
                                    teaching which you have heard from me,
                                    living by the faith and the love which are
                                    ours in Christ Jesus; guard the truth that
                                    has been entrusted to you by the Holy Spirit
                                    living within us” (2Tim. 1:21).
                                </Typography>
                            </Section>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        sm={5}
                        sx={{
                            display: { md: "block", sm: "block", xs: "none" },
                        }}
                    >
                        <Box
                            sx={{
                                height: `100%`,
                            }}
                        >
                            <Avatar
                                src={g1}
                                variant="square"
                                sx={{
                                    height: `100%`,
                                    width: `100%`,
                                    // borderRadius: {
                                    //     md: 15,
                                    //     sm: 15,
                                    //     xs: 15,
                                    // },
                                    borderTopLeftRadius: {
                                        md: 100,
                                        sm: 100,
                                        xs: 100,
                                    },
                                    borderBottomRightRadius: {
                                        md: 100,
                                        sm: 100,
                                        xs: 100,
                                    },
                                    borderLeft: `5px solid ${colors.bgColor2}`,
                                    borderRight: `5px solid ${colors.bgColor2}`,
                                    boxShadow: `0 0 5px 2px ${colors.bgColor4}`,
                                    img: {
                                        objectFit: "cover",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container sx={{ mt: 10 }} columnSpacing={4}>
                    <Grid item sm={7} xs={12} order={{ md: 2, sm: 2, xs: 2 }}>
                        <Box>
                            <Section
                                head={"A life of prayer"}
                                subHead={
                                    "Priestly life is a life of prayer by which the priest lives with Christ and in Christ, and grows in faith, in hope and in love. “For me to live is Christ” (Phil. 1:21).“"
                                }
                            >
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        lineHeight: 1.6,
                                        opacity: 0.8,
                                    }}
                                >
                                    This way of life of prayer a seminarian
                                    expresses and grows in his union with God.
                                    Therefore, all seminarians after due
                                    preparation are to participate in the
                                    Eucharist daily. Following the communal
                                    timetable, seminarians also shall
                                    participate in all the communal prayers
                                    especially the liturgy of the hours.
                                    Meditation has to be a normal practice of
                                    seminarians, for at least 25 minutes a day,
                                    without forgetting to visit the blessed
                                    sacrament. Daily rosary is also highly
                                    recommended for a seminarian, whether
                                    private or communal. Along with prayer life,
                                    each seminarian shall approach the sacrament
                                    of penance, at least once a month.
                                </Typography>
                            </Section>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        sm={5}
                        sx={{
                            display: { md: "block", sm: "block", xs: "none" },
                        }}
                        order={{ md: 1, sm: 1, xs: 1 }}
                    >
                        <Box
                            sx={{
                                height: `100%`,
                            }}
                        >
                            <Avatar
                                src={g2}
                                variant="square"
                                sx={{
                                    height: `100%`,
                                    width: `100%`,
                                    // borderRadius: {
                                    //     md: 15,
                                    //     sm: 15,
                                    //     xs: 15,
                                    // },
                                    borderTopLeftRadius: {
                                        md: 100,
                                        sm: 100,
                                        xs: 100,
                                    },
                                    borderBottomRightRadius: {
                                        md: 100,
                                        sm: 100,
                                        xs: 100,
                                    },
                                    borderLeft: `5px solid ${colors.bgColor2}`,
                                    borderRight: `5px solid ${colors.bgColor2}`,
                                    boxShadow: `0 0 5px 2px ${colors.bgColor4}`,
                                    img: {
                                        objectFit: "cover",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container sx={{ my: 10 }} columnSpacing={4}>
                    <Grid item sm={7} xs={12}>
                        <Box>
                            <Section
                                head={"A life of service"}
                                subHead={
                                    "The priest is the minister who is entirely dedicated to the loving service of God and of His people."
                                }
                            >
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        lineHeight: 1.6,
                                        opacity: 0.8,
                                    }}
                                >
                                    “And these were his gifts: some to be
                                    apostles, some prophets, some evangelists,
                                    some pastors and teachers, to equip God's
                                    people for work in his service, to the
                                    building up of the body of Christ” (Ephes.
                                    4:11-12)
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        lineHeight: 1.6,
                                        opacity: 0.8,
                                        mt: 4,
                                    }}
                                >
                                    The spirit of service demands generosity,
                                    concern for others, to be able to undertake
                                    work and be held accountable, readiness to
                                    forget oneself and punctuality. Each
                                    seminarian is required to make effort to
                                    acquire these virtues in order to foster the
                                    life of service, since to be a priest is to
                                    be of service for others. A seminarian is
                                    required to fulfil all the tasks given and
                                    other services demanded of him with good
                                    will, efficiency and alive. Seminarians
                                    appointed to perform special duties shall
                                    endeavor to fulfil them cheerfully,
                                    willingly and to the best of their ability.
                                    Seminarians therefore, shall play an active
                                    part in the seminary projects, and perform
                                    the tasks instead of depending on the
                                    workers from outside.
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        lineHeight: 1.6,
                                        opacity: 0.8,
                                        mt: 4,
                                    }}
                                >
                                    Kipalapala Senior Seminary is under the
                                    patronage of St. Paul the apostle. In
                                    character and in spirit, St. Paul was brave
                                    and serious in fulfilling the work of
                                    preaching the gospel, and in the spirit of
                                    service he was eager to work for the good of
                                    the people of God. On every 25th January
                                    each year, is the feast of the conversion of
                                    St. Paul. In our seminary, this day is held
                                    important when we honor St. Paul and imitate
                                    his life examples. On this day, seminarians
                                    of 2nd and 3rd year are installed into the
                                    ministries of Lector and Acolyte
                                    respectively.
                                </Typography>
                            </Section>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        sm={5}
                        xs={12}
                        sx={{
                            display: { md: "block", sm: "block", xs: "none" },
                        }}
                    >
                        <Box
                            sx={{
                                height: `100%`,
                            }}
                        >
                            <Avatar
                                src={g3}
                                variant="square"
                                sx={{
                                    height: `100%`,
                                    width: `100%`,
                                    // borderRadius: {
                                    //     md: 15,
                                    //     sm: 15,
                                    //     xs: 15,
                                    // },
                                    borderTopLeftRadius: {
                                        md: 100,
                                        sm: 100,
                                        xs: 100,
                                    },
                                    borderBottomRightRadius: {
                                        md: 100,
                                        sm: 100,
                                        xs: 100,
                                    },
                                    borderLeft: `5px solid ${colors.bgColor2}`,
                                    borderRight: `5px solid ${colors.bgColor2}`,
                                    boxShadow: `0 0 5px 2px ${colors.bgColor4}`,
                                    img: {
                                        objectFit: "cover",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </SpacingContent>
        </PageLayout>
    );
};

export default SeminaryLifePage;
