import React from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import SpacingContent from "./SpacingContent";
import { colors } from "../assets/utils/colors";
import SocialNetworks from "./SocialNetworks";
import urbanianaImg from "../assets/media/images/urbaniana.png";
import { Outbound } from "@mui/icons-material";

const Footer = () => {
    const date = new Date();
    return (
        <>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                }}
            >
                <Box
                    sx={{
                        p: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                        position: "relative",
                    }}
                >
                    <Box sx={{ textAlign: "center" }}>
                        <Box
                            sx={{
                                position: "absolute",
                                left: 10,
                                top: { md: "auto", sm: "auto", xs: 0 },
                            }}
                        >
                            <Avatar
                                src={urbanianaImg}
                                variant="square"
                                sx={{
                                    img: { objectFit: "contain" },
                                    height: { md: 80, sm: 80, xs: 50 },
                                    width: "100%",
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                position: "absolute",
                                right: 10,
                                top: { md: "auto", sm: "auto", xs: 0 },
                            }}
                        >
                            <Avatar
                                src={urbanianaImg}
                                variant="square"
                                sx={{
                                    img: { objectFit: "contain" },
                                    height: { md: 80, sm: 80, xs: 50 },
                                    width: "100%",
                                }}
                            />
                        </Box>
                        <Typography
                            sx={{
                                fontSize: { md: 25, sm: 25, xs: 16 },
                                fontWeight: "bold",
                                background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                backgroundClip: "text",
                                textFillColor: "transparent",
                                my: 3,
                            }}
                        >
                            Affiliated to Urbaniana University - Rome
                        </Typography>
                        <a
                            href="https://www.urbaniana.va/it.html"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Button
                                variant="contained"
                                size="large"
                                color="success"
                                sx={{
                                    px: 4,
                                    borderRadius: "50px !important",
                                    border: `2px solid ${colors.secondary}`,
                                }}
                                endIcon={<Outbound />}
                            >
                                Get in touch
                            </Button>
                        </a>
                    </Box>
                </Box>
                <SpacingContent>
                    <Box sx={{ display: "flex", width: "100%" }}>
                        <Box
                            sx={{
                                background: `linear-gradient(180deg, ${colors.secondary}, ${colors.secondary})`,
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-around",
                                flexDirection: {
                                    md: "row",
                                    sm: "row",
                                    xs: "column",
                                },
                                alignItems: "center",
                                p: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    mb: { md: 0, sm: 0, xs: 2 },
                                }}
                            >
                                All rights reserved | St's Kipalapala &copy;{" "}
                                {date.getFullYear()}
                            </Typography>
                            <SocialNetworks />
                        </Box>
                    </Box>
                </SpacingContent>
            </Box>
        </>
    );
};

export default Footer;
