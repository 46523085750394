import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { reflectionsEndpoints } from "./reflectionsEndpoints";

export const reflectionsAPI = createApi({
    reducerPath: "reflectionsAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
    endpoints: (builder) => ({
        getPublicReflections: builder.query({
            query: () => `${reflectionsEndpoints.GET_ACTIVE_REFLECTIONS}`,
        }),
        getSingleReflection: builder.query({
            query: (payload) => `${reflectionsEndpoints.GET_BY_ID}/${payload}`,
        }),
    }),
});

export const {
    useGetPublicReflectionsQuery,
    useGetSingleReflectionQuery,
    useLazyGetPublicReflectionsQuery,
} = reflectionsAPI;
