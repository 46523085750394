import React from "react";
import PageLayout from "../layouts/PageLayout";
import SpacingContent from "../../components/SpacingContent";
import Section from "../../components/Section";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";

// => Images
import g1 from "../../assets/media/images/gallery/g1.jpg";
import g2 from "../../assets/media/images/gallery/g4.jpg";
import g3 from "../../assets/media/images/gallery/g7.JPG";

const AboutUsPage = () => {
    const aboutSections = [
        {
            id: 2,
            img: g3,
            imgOrder: 2,
            descOrder: 1,
            description:
                "Two years later, in 1925, the Seminary was moved to Kipalapala, Tabora, a place, which was central and accessible for students who came for priestly formation not only from Tanzania (Tanganyika by then), but also from neighbouring countries such as Malawi and Zambia. From 1942 on, however, it has served Tanzania alone, as a regional seminary. The growth and development of Kipalapala Seminary over the years has been gradual and fruitful, and changes that may have taken place have been in view of making it a better training centre. One such change, a structural one, was made in January, 1967 when the Tanzanian Episcopal Conference decided to establish two philosophy centres: one at Ntungamo and the other at Kibosho; with Kipalapala reserved for theology. In effect, therefore, the three seminaries comprise one interrelated seminary system.",
        },
        {
            id: 3,
            img: g1,
            imgOrder: 1,
            descOrder: 2,
            description:
                "In October of the same year 1967, an academic change was introduced when Kipalapala Seminary affiliated with Makerere University, to enable students of the three seminaries to earn the latter's Diploma in Theology. In addition to the subsequent achievement of the students themselves, one fact of this move has been to raise the standard of academic excellence, without prejudice, however, to the pastoral orientation and training of the seminarians.",
        },
        {
            id: 4,
            img: g2,
            imgOrder: 2,
            descOrder: 1,
            description:
                "Again in 1967, a significant personnel change was effected with the installation of the first diocesan priest as rector of Kipalapala, together the addition of other diocesan priests staff members. The history of affiliation began again in 1988 when the seminary was affiliated to the Faculty of Theology of Urbaniana Pontifical University, the affiliation that has continued until now.",
        },
    ];

    return (
        <PageLayout
            pageHead={"History kipalapala seminary"}
            pageSubHead={
                "Certainly! The St. Paul Senior Seminary has its historical roots in the provisional major seminary established by the White Fathers in 1921 at Utinta, located on the shores of Lake Tanganyika. 🙏✝️"
            }
        >
            <SpacingContent maxSpace={11}>
                <Section>
                    <Box
                        sx={{
                            background: `linear-gradient(360deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                            borderRadius: 5,
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                position: "relative",
                                display: "flex",
                                mt: 10,
                            }}
                        >
                            <Box
                                className="img1"
                                sx={{
                                    height: 250,
                                    width: "100%",
                                    position: "relative",
                                    top: -50,
                                    borderRadius: 100,
                                    overflow: "hidden",
                                }}
                            >
                                <Avatar
                                    src={g2}
                                    variant="square"
                                    sx={{
                                        height: 250,
                                        width: `100%`,
                                        borderRadius: 5,
                                        img: {
                                            objectFit: "cover",
                                        },
                                    }}
                                />
                            </Box>
                            <Box
                                className="img2"
                                sx={{
                                    height: 250,
                                    width: "100%",
                                    position: "relative",
                                    borderRadius: 100,
                                    top: 0,
                                    overflow: "hidden",
                                    mx: { md: 5, sm: 5, xs: 2 },
                                }}
                            >
                                <Avatar
                                    src={g1}
                                    variant="square"
                                    sx={{
                                        height: 250,
                                        width: `100%`,
                                        borderRadius: 5,
                                        img: {
                                            objectFit: "cover",
                                        },
                                    }}
                                />
                            </Box>
                            <Box
                                className="img3"
                                sx={{
                                    height: 250,
                                    width: "100%",
                                    position: "relative",
                                    top: -50,
                                    borderRadius: 100,
                                    overflow: "hidden",
                                }}
                            >
                                <Box>
                                    <Avatar
                                        src={g3}
                                        variant="square"
                                        sx={{
                                            height: 250,
                                            width: `100%`,
                                            borderRadius: 5,
                                            img: {
                                                objectFit: "cover",
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                p: { md: 5, sm: 5, xs: 2 },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 15,
                                    lineHeight: 1.6,
                                    opacity: 0.8,
                                    background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                    backgroundClip: "text",
                                    textFillColor: "transparent",
                                    textAlign: "center",
                                }}
                            >
                                Historically, the root of St. Paul Senior
                                Seminary may be traced to the provisional major
                                seminary founded by White Fathers in 1921 at
                                Utinta, on Lake Tanganyika. Its history goes
                                even a little earlier associated with the bold
                                attempt of Bishop Henry Leorard of the Vicariate
                                of Unyanyembe who began a senior seminary with
                                four seminarians at Ushirombo on 5th of
                                September 1918. These were shifted to Utinta in
                                the vicariate of Tanganyika in 1921 to join with
                                seminarians from the vicariates of Tanganyika,
                                Banguelo and Nyassa to form the regional senior
                                seminary at Utinta in 1923.
                            </Typography>
                        </Box>
                    </Box>
                    {aboutSections.map((section) => (
                        <Box
                            key={section.id}
                            sx={{
                                borderRadius: 5,
                                my: { md: 10, sm: 10, xs: 5 },
                                p: { md: 5, sm: 5, xs: 2 },
                                boxShadow: `0 0 10px 5px${colors.bgColor1}`,
                                backdropFilter: `blur(5px)`,
                            }}
                        >
                            <Grid container>
                                <Grid
                                    item
                                    sm={6}
                                    xs={12}
                                    order={{
                                        md: section.imgOrder,
                                        sm: section.imgOrder,
                                        xs: 1,
                                    }}
                                >
                                    <Box>
                                        <Avatar
                                            src={section.img}
                                            variant="square"
                                            sx={{
                                                height: {
                                                    md: "60vh",
                                                    sm: "60vh",
                                                    xs: "30vh",
                                                },
                                                width: `100%`,
                                                // borderRadius: {
                                                //     md: 15,
                                                //     sm: 15,
                                                //     xs: 15,
                                                // },
                                                borderTopLeftRadius: {
                                                    md: 100,
                                                    sm: 100,
                                                    xs: 50,
                                                },
                                                borderBottomRightRadius: {
                                                    md: 100,
                                                    sm: 100,
                                                    xs: 50,
                                                },
                                                borderLeft: `15px solid ${colors.bgColor1}`,
                                                borderRight: `15px solid ${colors.bgColor1}`,
                                                boxShadow: `0 0 5px 2px ${colors.bgColor1}`,
                                                img: {
                                                    objectFit: "cover",
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    sm={6}
                                    xs={12}
                                    order={{
                                        md: section.descOrder,
                                        sm: section.descOrder,
                                        xs: 2,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            p: { md: 5, sm: 5, xs: 2 },
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 15,
                                                lineHeight: 1.6,
                                                opacity: 0.8,
                                            }}
                                        >
                                            {section.description}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
                </Section>
            </SpacingContent>
        </PageLayout>
    );
};

export default AboutUsPage;
