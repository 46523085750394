import React from "react";
import PageLayout from "../layouts/PageLayout";
import SpacingContent from "../../components/SpacingContent";
import Section from "../../components/Section";
import DailyReflectionWidget from "../../components/widgets/DailyReflectionWidget";
import { Grid } from "@mui/material";
import { useGetPublicReflectionsQuery } from "../../api/reflectionsAPI/reflectionsAPI";
import { setDailyReflections } from "../../state/features/dailyReflection/dailyReflectionSlice";
import { reflectionsSelector } from "../../state/features/selectors";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../hooks/useRTK";

const DailyReflections = () => {
    // ############### CONST ####################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### Redux state ##############
    const { dailyReflections } = useSelector(reflectionsSelector);

    // ############## RTK #################
    const { isSuccess, data: reflectionsData } = useGetPublicReflectionsQuery();

    // ############## useEffect ##################
    const rtkReflectionsAction = (data) => {
        dispatch(setDailyReflections(data));
    };
    RTK.useRTKFetch(isSuccess, reflectionsData, rtkReflectionsAction);

    return (
        <>
            <PageLayout
                pageHead={"Our daily reflections"}
                pageSubHead={
                    "Certainly! Life in the seminary revolves around three core pillars: study, prayer, and service.🙏✝️"
                }
            >
                <SpacingContent maxSpace={11}>
                    <Section>
                        <Grid container spacing={4}>
                            {dailyReflections?.map((reflection, index) => (
                                <Grid item sm={6} key={index}>
                                    <DailyReflectionWidget
                                        reflection={reflection}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Section>
                </SpacingContent>
            </PageLayout>
        </>
    );
};

export default DailyReflections;
