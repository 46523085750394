import React, { useRef } from "react";
import { Swiper } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import { Box,Grid } from "@mui/material";
// import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
// import { colors } from "../../assets/utils/colors";

const CustomSwiper = ({ conHeight, bgColor, children, element }) => {
    const sliderRef = useRef();
    return (
        <Box
            sx={{
                width: { md: "100%", xs: "100%" },
                position: "relative",
                height: conHeight ? conHeight : 500,
                bgcolor: bgColor ? bgColor : "none",
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Grid container justifyContent={"center"}>
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        sx={{
                            // borderRadius: 5,
                            overflow: "hidden",
                        }}
                    >
                        <Swiper
                            navigation={{
                                prevEl: ".swiper-button-prev",
                                nextEl: ".swiper-button-next",
                            }}
                            modules={[Pagination, Navigation, Autoplay]}
                            autoplay={{ delay: 4000 }}
                            loop
                            pagination={{
                                el: `.${element}`,
                                type: "custom",
                                renderCustom: function (
                                    swiper,
                                    current,
                                    total
                                ) {
                                    return current + "/" + total;
                                },
                            }}
                            // onSlideChange={() => console.log('slide change')}
                            effect={"cube"}
                            onSwiper={(it) => (sliderRef.current = it)}
                            breakpoints={{
                                // when window width is >= 320px
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 24,
                                },
                                // when window width is >= 480px
                                480: {
                                    slidesPerView: 1,
                                    spaceBetween: 24,
                                },
                                // when window width is >= 640px
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 24,
                                },
                            }}
                        >
                            {children}
                        </Swiper>
                        {/* <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                mt: 4,
                            }}
                        >
                            <Box
                                sx={{
                                    height: 30,
                                    width: 100,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 3,
                                    mr: 2,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                }}
                                className={element}
                            ></Box>

                            <Button
                                variant="outlined"
                                size="small"
                                sx={{ mr: 2 }}
                                onClick={() => sliderRef.current?.slidePrev()}
                            >
                                <ArrowCircleLeft />
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={() => sliderRef.current?.slideNext()}
                            >
                                <ArrowCircleRight />
                            </Button>
                        </Box> */}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default CustomSwiper;
