import React from "react";
import SpacingContent from "../SpacingContent";
import Section from "../Section";
import { Grid } from "@mui/material";
import {
    ChurchOutlined,
    DisplaySettingsOutlined,
    SchoolOutlined,
} from "@mui/icons-material";
import SeminaryLifeWidget from "../widgets/SeminaryLifeWidget";

// => Images
import g1 from "../../assets/media/images/gallery/g1.jpg";
import g2 from "../../assets/media/images/gallery/g4.jpg";
import g3 from "../../assets/media/images/gallery/g7.JPG";

const SeminaryLife = () => {
    const seminaryLife = [
        {
            id: 1,
            img: g1,
            name: "A life of study",
            icon: <SchoolOutlined className="icon" />,
            desc: "Since a priest as a pastor is a teacher, a prophet and a counsellor for his people, the studies in the seminary must foster thinking, and instill a basic understanding of Crist's message ...",
        },
        {
            id: 2,
            img: g3,
            name: "A life of prayer",
            icon: <ChurchOutlined className="icon" />,
            desc: "Priestly life is a life of prayer by which the priest lives with Christ and in Christ, and grows in faith, in hope and in love. For me to live is Christ” (Phil. 1:21) ...",
        },
        {
            id: 3,
            img: g2,
            name: "A life of service",
            icon: <DisplaySettingsOutlined className="icon" />,
            desc: "The priest is the minister who is entirely dedicated to the loving service of God and of His people. And these were his gifts: some to be apostles, some prophets, some evangelists, some pastors and teachers ...",
        },
    ];
    return (
        <div>
            <SpacingContent maxSpace={11}>
                <Section
                    head={"Seminary life"}
                    subHead={
                        "life in the seminary and its organizations are focused on this ministry"
                    }
                >
                    <Grid container spacing={4}>
                        {seminaryLife.map((life, index) => (
                            <Grid item sm={4} key={index}>
                                <SeminaryLifeWidget life={life} />
                            </Grid>
                        ))}
                    </Grid>
                </Section>
            </SpacingContent>
        </div>
    );
};

export default SeminaryLife;
