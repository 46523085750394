import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Avatar, Box, Stack, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../assets/media/images/logo.png";
import SpacingContent from "./SpacingContent";
import { Segment } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { colors } from "../assets/utils/colors";
import NavList from "./NavList";
import SubTopBar from "./SubTopBar";
import { navLinks } from "./NavLinks";

// ################# CONSTANT ###############
const drawerWidth = 250;

// ################# STYLED APPBAR ###############
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    // backgroundColor: "transparent",
    backgroundColor: colors.primary,
    boxShadow: "none !important",
    background: `rgba(23, 148, 202, .5)`,
    backdropFilter: `blur(5px)`,
}));

// ################# TOPBAR RIGHT ###############
const TopbarRight = styled(Stack)({});

// !###################### MAIN FUNC ############################
const HomeTopBar = ({ mobileOpen, handleDrawerToggle }) => {
    const links = navLinks;

    return (
        <>
            <AppBar position="fixed">
                {/* <SpacingContent> */}
                <Toolbar
                    sx={{
                        px: `0 !important`,
                        justifyContent: "space-between",
                        position: "relative",
                    }}
                >
                    {/* ################### LEFT ################# */}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            px: { md: 2, sm: 2, xs: 1 },
                            borderTopRightRadius: 100,
                            zIndex: 10,
                            py: 0.5,
                            background: colors.primary,
                        }}
                    >
                        <Typography
                            noWrap
                            component="div"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: 2,
                                p: 0.5,
                            }}
                        >
                            <Link to={"/"}>
                                <Avatar
                                    variant="square"
                                    src={logo}
                                    sx={{
                                        height: { md: 90, sm: 90, xs: 70 },
                                        width: 80,
                                        img: {
                                            objectFit: "contain",
                                            width: "100%",
                                        },
                                    }}
                                />
                            </Link>
                        </Typography>
                        <Box sx={{ ml: { md: 1.5, sm: 1.5, xs: 0.5 } }}>
                            <Typography
                                sx={{
                                    fontSize: { md: 13, sm: 13, xs: 10 },
                                    textTransform: "uppercase",
                                }}
                            >
                                St. paul's
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { md: 13, sm: 13, xs: 10 },
                                    textTransform: "uppercase",
                                }}
                            >
                                Senior seminary
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { md: 13, sm: 13, xs: 10 },
                                    textTransform: "uppercase",
                                }}
                            >
                                Kipalapala - Tabora, Tanzania
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 5,
                        }}
                    >
                        {/* ########## Top Subnab bar ########### */}
                        <SubTopBar />

                        {/* ########## Bottom Subnab bar ########### */}
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 5,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: colors.primary,
                            }}
                        >
                            <SpacingContent>
                                <Toolbar
                                    sx={{
                                        px: { sm: `0 !important` },
                                        justifyContent: "space-between",
                                        position: "relative",
                                    }}
                                >
                                    <Box></Box>
                                    {/* ################### CENTER ################# */}
                                    <Box sx={{ textAlign: "center" }}></Box>
                                    {/* ################### RIGHT ################# */}
                                    <TopbarRight
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={3}
                                    >
                                        <Box>
                                            <NavList links={links} />
                                        </Box>
                                        {mobileOpen ? (
                                            <IconButton
                                                onClick={handleDrawerToggle}
                                                size="small"
                                                sx={{
                                                    display: {
                                                        xs: "block",
                                                        sm: "none",
                                                    },
                                                    marginTop: {
                                                        md: 1.3,
                                                        sm: 1.3,
                                                        xs: 3,
                                                    },
                                                    mr: 10,
                                                }}
                                            >
                                                <CloseIcon
                                                    sx={{
                                                        fontSize: 30,
                                                        color: colors.bgColor1,
                                                        marginTop: 0.5,
                                                        mr: -2,
                                                    }}
                                                />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                onClick={handleDrawerToggle}
                                                size="small"
                                                sx={{
                                                    display: {
                                                        xs: "block",
                                                        sm: "none",
                                                    },
                                                    marginTop: {
                                                        md: 1.3,
                                                        sm: 1.3,
                                                        xs: 3,
                                                    },
                                                    mr: 10,
                                                }}
                                            >
                                                <Segment
                                                    sx={{
                                                        fontSize: 30,
                                                        color: colors.bgColor1,
                                                        marginTop: 0.5,
                                                        mr: -2,
                                                    }}
                                                />
                                            </IconButton>
                                        )}
                                    </TopbarRight>
                                </Toolbar>
                            </SpacingContent>
                        </Box>
                    </Box>
                </Toolbar>
                {/* </SpacingContent> */}
            </AppBar>
        </>
    );
};

export default HomeTopBar;
