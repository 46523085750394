import React from "react";
import PageLayout from "../layouts/PageLayout";
import SpacingContent from "../../components/SpacingContent";
import Section from "../../components/Section";
import { Grid } from "@mui/material";
import NewsWidget from "../../components/widgets/NewsWidget";
import { useGetPublicArticlesQuery } from "../../api/articlesAPI/articlesAPI";
import { setArticles } from "../../state/features/articles/articlesSlice";
import { articlesSelector } from "../../state/features/selectors";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../hooks/useRTK";
import ArticleWidget from "../../components/widgets/ArticleWidget";

const NewsAndEventsPage = () => {
    // ############### CONST ####################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### Redux state ##############
    const { articles } = useSelector(articlesSelector);

    // ############## RTK #################
    const { isSuccess, data: articlesData } = useGetPublicArticlesQuery();

    // ############## useEffect ##################
    const rtkArticlesAction = (data) => {
        dispatch(setArticles(data));
    };
    RTK.useRTKFetch(isSuccess, articlesData, rtkArticlesAction);

    return (
        <PageLayout
            pageHead={"Our articles"}
            pageSubHead={"Get updates on every step we take.🙏✝️"}
        >
            <SpacingContent maxSpace={11}>
                <Section>
                    <Grid container spacing={4}>
                        {articles.map((article, index) => (
                            <Grid item sm={4} xs={12} key={index}>
                                <ArticleWidget article={article} />
                            </Grid>
                        ))}
                    </Grid>
                </Section>
            </SpacingContent>
        </PageLayout>
    );
};

export default NewsAndEventsPage;
