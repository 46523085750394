// => systemConfig selector
export const systemConfigSelector = (state) => state.systemConfigReducer;

// => gallaries selector
export const gallariesSelector = (state) => state.gallariesReducer;

// => articles selector
export const articlesSelector = (state) => state.articlesReducer;

// => reflections selector
export const reflectionsSelector = (state) => state.reflectionsReducer;
