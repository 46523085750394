import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../hooks/useColor";
import { KeyboardDoubleArrowDown } from "@mui/icons-material";

const Section = ({ head, subHead, children, bgColor }) => {
    return (
        <>
            <Box
                sx={{
                    height: "100%",
                    pt: { md: 10, xs: 5 },
                    pb: { md: 10, xs: 5 },
                    background: bgColor && bgColor,
                }}
            >
                <Grid
                    container
                    justifyContent={"center"}
                    rowSpacing={{ md: 8, xs: 2 }}
                >
                    {head && (
                        <Grid item sm={8} xs={12}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: { md: 35, xs: 25 },
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        color: colors.primary,
                                        opacity: 0.8,
                                        textTransform: "uppercase",
                                        position: "relative",
                                        background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                        backgroundClip: "text",
                                        textFillColor: "transparent",
                                    }}
                                >
                                    {head}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        textAlign: "center",
                                        color: colors.textColor,
                                        opacity: 0.8,
                                    }}
                                >
                                    {subHead}
                                </Typography>
                                <KeyboardDoubleArrowDown
                                    sx={{
                                        fontSize: 35,
                                        opacity: 0.8,
                                    }}
                                />
                            </Box>
                        </Grid>
                    )}

                    <Grid item sm={12} xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Section;
