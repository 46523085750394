import React, { useState } from "react";
import {
    Box,
    Button,
    Drawer,
    IconButton,
    Toolbar,
    useMediaQuery,
} from "@mui/material";
import { colors } from "../assets/utils/colors";
import {
    Close,
    ContactEmergency,
    EmailOutlined,
    PhoneAndroidOutlined,
} from "@mui/icons-material";
import SocialNetworks from "./SocialNetworks";

const SubTopBar = () => {
    const screenWidth = useMediaQuery("(min-width:600px)");
    const { window } = "props";
    const container =
        window !== undefined ? () => window().document.body : undefined;

    const [drawerToggle, setDrawerToggle] = useState(false);
    const handleDrawerOpen = () => {
        setDrawerToggle(true);
    };
    const handleDrawerClose = () => {
        setDrawerToggle(false);
    };

    return (
        <>
            <Drawer
                anchor={"top"}
                container={container}
                variant="temporary"
                open={drawerToggle}
                onClose={handleDrawerClose}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: "100%", // Change the width of drawer in mobile
                    },
                }}
            >
                <Toolbar />
                <Box
                    sx={{
                        height: 200,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                width: "100%",
                            }}
                        >
                            <Button
                                color="secondary"
                                sx={{ color: colors.bgColor2 }}
                                startIcon={<PhoneAndroidOutlined />}
                            >
                                +255 743 967 568
                            </Button>
                            <Button
                                color="secondary"
                                sx={{ mb: 3, color: colors.bgColor2 }}
                                startIcon={<EmailOutlined />}
                            >
                                kipalapala@gmail.com
                            </Button>
                        </Box>
                        <Box>
                            <SocialNetworks />
                        </Box>
                    </Box>
                </Box>
            </Drawer>
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: "50%",
                    zIndex: 5,
                    display: "flex",
                    alignItems: "center",
                    background: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                }}
            >
                <Toolbar
                    sx={{
                        px: { sm: `0 !important` },
                        justifyContent: "space-between",
                        position: "relative",
                        width: "100%",
                    }}
                >
                    {screenWidth ? (
                        <>
                            <Box></Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    width: "100%",
                                }}
                            >
                                <a
                                    href={`mailTo:stpaulsem@yahoo.com`}
                                    rel="noopener noreferrer"
                                >
                                    <Button
                                        startIcon={<EmailOutlined />}
                                        sx={{ mr: 1 }}
                                    >
                                        stpaulsem@yahoo.com
                                    </Button>
                                </a>
                                <a
                                    href={`tel:+255 768 498 866`}
                                    rel="noopener noreferrer"
                                >
                                    <Button
                                        startIcon={<PhoneAndroidOutlined />}
                                        sx={{ ml: 1 }}
                                    >
                                        +255 768 498 866
                                    </Button>
                                </a>
                            </Box>
                            <Box>
                                <SocialNetworks />
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    position: "absolute",
                                    right: 6,
                                    borderRadius: 50,
                                }}
                            >
                                {drawerToggle ? (
                                    <IconButton
                                        size="small"
                                        sx={{
                                            p: 0.5,
                                            background: `linear-gradient(360deg, ${colors.primary}, ${colors.secondary})`,
                                        }}
                                        onClick={() => {
                                            handleDrawerClose();
                                        }}
                                    >
                                        <Close
                                            sx={{
                                                fontSize: 25,
                                                color: colors.bgColor2,
                                            }}
                                        />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        size="small"
                                        sx={{
                                            p: 0.5,
                                            background: `linear-gradient(360deg, ${colors.primary}, ${colors.secondary})`,
                                        }}
                                        onClick={() => {
                                            handleDrawerOpen();
                                        }}
                                    >
                                        <ContactEmergency
                                            sx={{
                                                fontSize: 25,
                                                color: colors.bgColor2,
                                            }}
                                        />
                                    </IconButton>
                                )}
                            </Box>
                        </>
                    )}
                </Toolbar>
            </Box>
        </>
    );
};

export default SubTopBar;
