import React, { useState } from "react";
import SpacingContent from "../../components/SpacingContent";
import Section from "../../components/Section";
import {
    Box,
    IconButton,
    Typography,
    Grid,
    useMediaQuery,
} from "@mui/material";
import PageLayout from "../layouts/PageLayout";
import GallaryView from "../../components/GallaryView";
import { SlideshowRounded } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import SlideShowView from "../../components/SlideShowView";

// => Images
import GalleryWidget from "../../components/widgets/GalleryWidget";
import { gallariesSelector } from "../../state/features/selectors";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import useRTK from "../../hooks/useRTK";
import { useGetPublicGallariesQuery } from "../../api/gallaryAPI/gallaryAPI";
import { setGallaries } from "../../state/features/gallaries/gallariesSlice";

const GalleryPage = () => {
    // ############### Comp State ####################
    const [toggler, setToggler] = React.useState(false);
    const [slideShowOpen, setSlideshowOpen] = useState(false);
    const [slideShow, setSlideshow] = useState([]);
    const [gallaryView, setGallaryView] = React.useState(null);

    // ############### CONST ####################
    const deviceWidth = useMediaQuery("(min-width:600px)");
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### Redux state ##############
    const { gallaries } = useSelector(gallariesSelector);

    // ############## RTK #################
    const {
        isSuccess,
        data: gallaryData,
    } = useGetPublicGallariesQuery();

    // ############## useEffect ##################
    const rtkGallaryAction = (data) => {
        dispatch(setGallaries(data));
    };
    RTK.useRTKFetch(isSuccess, gallaryData, rtkGallaryAction);

    // ############## FUNC ######################
    const handleGallaryView = (gallary) => {
        setToggler(!toggler);
        setGallaryView(gallary);
    };
    const handleSlideShow = () => {
        setSlideshowOpen(!slideShowOpen);
        let slideShow = [];
        gallaries.map((gallary) => {
            slideShow.push(gallary);
            return true;
        });
        setSlideshow(slideShow);
    };
    return (
        <>
            <GallaryView toggler={toggler} gallary={gallaryView} />
            <SlideShowView toggler={slideShowOpen} gallary={slideShow} />
            <PageLayout
                pageHead={"Seminary gallery"}
                pageSubHead={
                    "Certainly! Let us step into the hallowed halls of St. Paul's Senior Seminary—a sanctuary where faith, learning, and service intertwine. 🙏✝️"
                }
                action={
                    !isEmpty(gallaries) && (
                        <Box
                            sx={{
                                position: "sticky",
                                top: { md: 64, xs: 77 },
                                left: 0,
                                right: 0,
                                display: "flex",
                                justifyContent: "center",
                                zIndex: 10,
                            }}
                        >
                            <IconButton
                                size="small"
                                sx={{
                                    border: `5px solid ${colors.bgColor6}`,
                                    bgcolor: colors.bgColor3,
                                    borderRadius: `100px !important`,
                                    transition: `.3s all ease-in-out`,
                                    p: 1,
                                    px: 2,
                                    "& .play": {
                                        color: colors.secondary,
                                        transition: `.3s all ease-in-out`,
                                    },
                                    ":hover": {
                                        bgcolor: colors.bgColor3,
                                        transition: `.3s all ease-in-out`,
                                        "& .play": {
                                            transform: `scale(1.3)`,
                                            transition: `.3s all ease-in-out`,
                                        },
                                    },
                                }}
                                onClick={() => handleSlideShow()}
                            >
                                <SlideshowRounded
                                    className="play"
                                    sx={{ fontSize: 30, mr: 2 }}
                                />
                                <Typography
                                    sx={{ fontSize: 14, fontWeight: "bold" }}
                                >
                                    Gallaries
                                </Typography>
                            </IconButton>
                        </Box>
                    )
                }
            >
                <SpacingContent maxSpace={11}>
                    <Section>
                        {isEmpty(gallaries) ? (
                            <></>
                        ) : (
                            <Grid container spacing={4}>
                                {gallaries?.map((gallery, index) => (
                                    <Grid item sm={4} key={index} xs={6}>
                                        <GalleryWidget
                                            gallery={gallery}
                                            handlePreview={() =>
                                                handleGallaryView(gallery)
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Section>
                </SpacingContent>
            </PageLayout>
        </>
    );
};

export default GalleryPage;
