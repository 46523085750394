import React from "react";
import PageLayout from "../layouts/PageLayout";
import SpacingContent from "../../components/SpacingContent";
import Section from "../../components/Section";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";

// => Images
import { articlesSelector } from "../../state/features/selectors";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../hooks/useRTK";
import { useParams } from "react-router-dom";
import { useGetSingleArticleQuery } from "../../api/articlesAPI/articlesAPI";
import { setArticleDetails } from "../../state/features/articles/articlesSlice";
import moment from "moment";

const NewsAndEventDetails = () => {
    // ############# Const ##############
    const RTK = useRTK();
    const dispatch = useDispatch();
    const { article_id } = useParams();

    // ############ Redux state #################
    const { articleDetails } = useSelector(articlesSelector);

    // ############# RTK ##################
    const {
        isLoading,
        isSuccess,
        data: responseData,
    } = useGetSingleArticleQuery(article_id);

    // ############# RTK response ##################
    const rtkActions = (data) => {
        dispatch(setArticleDetails(data));
    };
    RTK.useRTKFetch(isSuccess, responseData, rtkActions);

    return (
        <>
            <PageLayout
                pageHead={articleDetails?.title}
                pageSubHead={`${articleDetails?.body} 🙏✝️`}
                isLoading={isLoading}
            >
                <SpacingContent maxSpace={11}>
                    <Section>
                        <Box
                            sx={{
                                background: `linear-gradient(360deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                borderRadius: 5,
                                p: 2,
                            }}
                        >
                            <Grid container>
                                <Grid item sm={6} xs={12}>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            position: "relative",
                                            display: "flex",
                                        }}
                                    >
                                        <Box
                                            className="img1"
                                            sx={{
                                                height: {
                                                    md: 400,
                                                    sm: 400,
                                                    xs: 250,
                                                },
                                                width: "100%",
                                                position: "relative",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                            }}
                                        >
                                            <Avatar
                                                src={articleDetails?.image}
                                                variant="square"
                                                sx={{
                                                    height: "100%",
                                                    width: `100%`,
                                                    borderRadius: 5,
                                                    img: {
                                                        objectFit: "cover",
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Box
                                        sx={{
                                            p: { md: 5, sm: 5, xs: 2 },
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 20,
                                                fontWeight: "bold",
                                                textTransform: "uppercase",
                                                opacity: 0.8,
                                            }}
                                        >
                                            {articleDetails?.title}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 15,
                                                lineHeight: 1.6,
                                                opacity: 0.8,
                                                background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                                backgroundClip: "text",
                                                textFillColor: "transparent",
                                                my: 2,
                                            }}
                                        >
                                            {articleDetails?.body}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: "bold",
                                                color: colors.blue,
                                            }}
                                        >
                                            Posted On :{" "}
                                            {moment(
                                                articleDetails?.created_at
                                            ).format("LL")}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Section>
                </SpacingContent>
            </PageLayout>
        </>
    );
};

export default NewsAndEventDetails;
