import React from "react";
import { Box, Button } from "@mui/material";
import Loader from "react-js-loader";
import { colors } from "../../assets/utils/colors";

const CustomSubmitButton = ({
    sx,
    loading,
    startIcon,
    onClick,
    btnColor,
    endIcon,
    variant,
    mt,
    children,
}) => {
    return (
        <>
            <Box sx={{ mt: mt ? 0 : 2 }}>
                <Button
                    variant={variant ? variant : "contained"}
                    onClick={onClick}
                    {...(loading ? "" : "")}
                    disabled={loading ? true : false}
                    startIcon={startIcon}
                    endIcon={endIcon}
                    color={btnColor}
                    className="app-btn"
                    fullWidth
                    sx={{ position: "relative", ...sx }}
                >
                    {children}
                    {loading && (
                        <Box
                            sx={{
                                position: "absolute",
                                left: 0,
                                right: 0,
                            }}
                        >
                            <Loader
                                type="bubble-loop"
                                bgColor={colors.info}
                                color={colors.info}
                                size={50}
                            />
                        </Box>
                    )}
                </Button>
            </Box>
        </>
    );
};

export default CustomSubmitButton;
