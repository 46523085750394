import React from "react";
import SpacingContent from "../SpacingContent";
import Section from "../Section";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { Link } from "react-router-dom";
import CustomSwiper from "../swipers/CustomSwiper";
import { SwiperSlide } from "swiper/react";

// => Images
import g1 from "../../assets/media/images/gallery/g1.jpg";
import g2 from "../../assets/media/images/gallery/g4.jpg";
import g3 from "../../assets/media/images/gallery/g7.JPG";
import AppButton from "../buttons/AppButton";

const AboutUs = () => {
    const showCaseImages = [
        {
            id: 1,
            img: g3,
        },
        {
            id: 3,
            img: g1,
        },
        {
            id: 4,
            img: g2,
        },
    ];

    return (
        <>
            <SpacingContent maxSpace={11}>
                <Section
                    head={"Short history of kipalapala"}
                    subHead={
                        "life in the seminary and its organizations are focused on this ministry"
                    }
                >
                    <Grid
                        container
                        sx={{
                            borderRadius: 5,
                            p: { md: 5, sm: 5, xs: 2 },
                            boxShadow: `0 0 10px 5px${colors.bgColor1}`,
                            backdropFilter: `blur(5px)`,
                        }}
                    >
                        <Grid item sm={6} xs={12}>
                            <Box
                                sx={{
                                    position: "relative",
                                }}
                            >
                                <CustomSwiper
                                    element={"blog"}
                                    conHeight={{ md: 450, sm: 450, xs: 200 }}
                                >
                                    {showCaseImages.map((image) => (
                                        <SwiperSlide
                                            key={image.id}
                                            style={{
                                                borderRadius: 25,
                                                background: "white",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    borderRadius: {
                                                        md: 10,
                                                        sm: 10,
                                                        xs: 5,
                                                    },
                                                    overflow: "hidden",
                                                }}
                                            >
                                                <Avatar
                                                    src={image.img}
                                                    variant="square"
                                                    sx={{
                                                        zIndex: 15,
                                                        height: {
                                                            md: "60vh",
                                                            sm: "60vh",
                                                            xs: "30vh",
                                                        },
                                                        borderRadius: {
                                                            md: 10,
                                                            sm: 10,
                                                            xs: 5,
                                                        },
                                                        width: `100%`,
                                                        img: {
                                                            objectFit: "cover",
                                                            borderRadius: {
                                                                md: 10,
                                                                sm: 10,
                                                                xs: 5,
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </SwiperSlide>
                                    ))}
                                </CustomSwiper>
                            </Box>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Box
                                sx={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    p: { md: 5, sm: 5, xs: 2 },
                                    flexDirection: "column",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 15,
                                        lineHeight: 1.6,
                                        opacity: 0.8,
                                    }}
                                >
                                    <Typography
                                        component={"span"}
                                        sx={{
                                            fontSize: 45,
                                            color: colors.primary,
                                        }}
                                    >
                                        H
                                    </Typography>
                                    istorically, the root of St. Paul Senior
                                    Seminary may be traced to the provisional
                                    major seminary founded by White Fathers in
                                    <strong> 1921</strong> at Utinta, on Lake
                                    Tanganyika. Its history goes even a little
                                    earlier associated with the bold attempt of
                                    Bishop Henry Leorard of the Vicariate of
                                    Unyanyembe who began a senior seminary with
                                    four seminarians at Ushirombo on 5th of
                                    September 1918.
                                </Typography>
                                <Link to={"/about-us"}>
                                    <AppButton
                                        title={
                                            "Know more about kipalapala serminary"
                                        }
                                    />
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Section>
            </SpacingContent>
        </>
    );
};

export default AboutUs;
