import React from "react";
import PageLayout from "../layouts/PageLayout";
import SpacingContent from "../../components/SpacingContent";
import Section from "../../components/Section";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";

// => Images
import { setDailyReflectionDetails } from "../../state/features/dailyReflection/dailyReflectionSlice";
import { reflectionsSelector } from "../../state/features/selectors";
import { useGetSingleReflectionQuery } from "../../api/reflectionsAPI/reflectionsAPI";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../hooks/useRTK";
import { useParams } from "react-router-dom";
import moment from "moment";

const DailyReflectionDetails = () => {
    // ############# Const ##############
    const RTK = useRTK();
    const dispatch = useDispatch();
    const { reflection_id } = useParams();

    // ############ Redux state #################
    const { reflectionDetails } = useSelector(reflectionsSelector);

    // ############# RTK ##################
    const {
        isLoading,
        isSuccess,
        data: responseData,
    } = useGetSingleReflectionQuery(reflection_id);

    // ############# RTK response ##################
    const rtkActions = (data) => {
        dispatch(setDailyReflectionDetails(data));
    };
    RTK.useRTKFetch(isSuccess, responseData, rtkActions);

    return (
        <>
            <PageLayout
                pageHead={reflectionDetails?.title}
                pageSubHead={`${reflectionDetails?.body} 🙏✝️`}
                isLoading={isLoading}
            >
                <SpacingContent maxSpace={11}>
                    <Section>
                        <Box
                            sx={{
                                background: `linear-gradient(360deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                borderRadius: 5,
                                p: { md: 5, sm: 5, xs: 0 },
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    position: "relative",
                                    display: "flex",
                                }}
                            >
                                <Grid container>
                                    <Grid item sm={6}>
                                        <Box
                                            sx={{
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                                p: { md: 5, sm: 5, xs: 2 },
                                                textAlign: {
                                                    md: "center",
                                                    sm: "center",
                                                    xs: "left",
                                                },
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 25,
                                                    fontWeight: "bold",
                                                    textTransform: "uppercase",
                                                    opacity: 0.8,
                                                }}
                                            >
                                                {reflectionDetails?.title}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: 20,
                                                    fontWeight: "bold",
                                                    background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                                    backgroundClip: "text",
                                                    textFillColor:
                                                        "transparent",
                                                    mt: 2,
                                                }}
                                            >
                                                {moment(
                                                    reflectionDetails?.date
                                                ).format("LL")}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Box
                                            className="img1"
                                            sx={{
                                                height: {
                                                    md: 400,
                                                    sm: 400,
                                                    xs: 250,
                                                },
                                                width: "100%",
                                                position: "relative",
                                                borderRadius: 10,
                                                overflow: "hidden",
                                            }}
                                        >
                                            <Avatar
                                                src={reflectionDetails?.image}
                                                variant="square"
                                                sx={{
                                                    height: "100%",
                                                    width: `100%`,
                                                    borderRadius: 5,
                                                    img: {
                                                        objectFit: "cover",
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box
                                sx={{
                                    p: { md: 5, sm: 5, xs: 2 },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 15,
                                        lineHeight: 1.6,
                                        opacity: 0.8,
                                        background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                        backgroundClip: "text",
                                        textFillColor: "transparent",
                                        textAlign: "center",
                                    }}
                                >
                                    {reflectionDetails?.body}
                                </Typography>
                            </Box>
                        </Box>
                    </Section>
                </SpacingContent>
            </PageLayout>
        </>
    );
};

export default DailyReflectionDetails;
