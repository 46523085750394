import { List, ListItem, ListItemButton, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { colors } from "../assets/utils/colors";

const NavList = ({ links }) => {
    return (
        <>
            <List
                sx={{
                    position: "relative",
                    height: 55,
                    p: 0,
                    display: {
                        md: "flex",
                        xs: "none",
                    },
                    justifyContent: "space-between",
                    alignItems: "center",
                    "& .item": {
                        p: 0,
                        "& .btn": { textTransform: "uppercase" },
                        "& .icon": {
                            opacity: 0.8,
                            fontSize: 20,
                            mr: 1,
                        },
                        "& .title": {
                            fontSize: 12,
                        },
                    },
                }}
            >
                {links?.map((link) => (
                    <NavLink to={link.url} key={link.id}>
                        {({ isActive }) => (
                            <ListItem className="item">
                                <ListItemButton
                                    className="btn"
                                    sx={{
                                        color: isActive
                                            ? colors.orange
                                            : colors.bgColor1,
                                    }}
                                >
                                    {link.icon}
                                    <Typography
                                        noWrap
                                        className="title"
                                        sx={{
                                            color: isActive
                                                ? colors.orange
                                                : colors.bgColor1,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {link.title}
                                    </Typography>
                                </ListItemButton>
                            </ListItem>
                        )}
                    </NavLink>
                ))}
            </List>
        </>
    );
};

export default NavList;
