export const colors = {
    primary: "#d00000",
    secondary: "#ffba08",
    tertiary: "#445397",
    bgColor1: "#f5eef0",
    bgColor2: "#fff7f7",
    bgColor3: "#ffe4e4",
    bgColor4: "#ffd0d0",
    bgColor5: "#ffbcbc",
    orange: "#fca311",
    green: "#00EE76",
    red: "#F43249",
    gray: "#D9D9D9",
    blue: "#027BBD",
};
