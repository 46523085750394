import React from "react";
import { Avatar, Box, Grid, Typography, styled } from "@mui/material";
import SpacingContent from "../../components/SpacingContent";
import logo from "../../assets/media/images/logo.png";
import { colors } from "../../assets/utils/colors";
import useRTK from "../../hooks/useRTK";
import PageLoader from "../../components/PageLoader";

const DrawerHeader = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const PageLayout = ({ pageHead, pageSubHead, isLoading, action, children }) => {
    // ############# Const ##############
    const RTK = useRTK();

    // ############## RTK #################
    RTK.useRTKMainData();
    return (
        <>
            <DrawerHeader />
            {isLoading ? (
                <>
                    <PageLoader />
                </>
            ) : (
                <>
                    {/* ########### Page Header ############## */}
                    <Box
                        sx={{
                            height: { md: 250, sm: 250, xs: 200 },
                            display: "flex",
                            py: 2,
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                            boxShadow: `0 0 10px 10px ${colors.bgColor3}`,
                        }}
                    >
                        <Box
                            sx={{
                                zIndex: 10,
                                opacity: 0.1,
                                position: "absolute",
                                display: {
                                    md: "none",
                                    sm: "none",
                                    xs: "block",
                                },
                            }}
                        >
                            <Avatar
                                src={logo}
                                variant="square"
                                sx={{
                                    height: { md: 120, sm: 120, xs: 130 },
                                    width: { md: 120, sm: 120, xs: "100%" },
                                    mt: 5,
                                    img: {
                                        objectFit: "contain",
                                    },
                                }}
                            />
                        </Box>
                        <SpacingContent maxSpace={11}>
                            <Box
                                sx={{
                                    mt: 5,
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    flexDirection: {
                                        md: "row",
                                        sm: "row",
                                        xs: "column",
                                    },
                                }}
                                gap={2}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            zIndex: 10,
                                            display: {
                                                md: "block",
                                                sm: "block",
                                                xs: "none",
                                            },
                                        }}
                                    >
                                        <Avatar
                                            src={logo}
                                            variant="square"
                                            sx={{
                                                height: {
                                                    md: 120,
                                                    sm: 120,
                                                    xs: 300,
                                                },
                                                width: {
                                                    md: 120,
                                                    sm: 120,
                                                    xs: "100%",
                                                },
                                                img: {
                                                    objectFit: "contain",
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <Grid container>
                                            <Grid item sm={8} xs={12}>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            md: 30,
                                                            sm: 30,
                                                            xs: 25,
                                                        },
                                                        lineHeight: 1.2,
                                                        fontWeight: "bold",
                                                        background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                                        backgroundClip: "text",
                                                        textFillColor:
                                                            "transparent",
                                                        // textTransform: "uppercase",
                                                        mb: 1,
                                                    }}
                                                >
                                                    {pageHead}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            md: 14,
                                                            sm: 14,
                                                            xs: 12,
                                                            opacity: 0.8,
                                                        },
                                                    }}
                                                >
                                                    {pageSubHead}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                {action && <Box>{action}</Box>}
                            </Box>
                        </SpacingContent>
                    </Box>

                    {/* ########### Page Content ############## */}
                    <Box>{children}</Box>
                </>
            )}
        </>
    );
};

export default PageLayout;
