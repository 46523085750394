import React from "react";
import { Avatar, Box, Grid, Typography, Button } from "@mui/material";
import SpacingContent from "../SpacingContent";
import { colors } from "../../assets/utils/colors";
import logo from "../../assets/media/images/logo.png";
import priest from "../../assets/media/images/priest.JPG";
import { ArrowRight, FormatQuoteOutlined } from "@mui/icons-material";

const Banner = () => {
    return (
        <>
            <Box
                sx={{
                    height: "100vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundImage: `url(${priest})`,
                    backgroundSize: {
                        md: `100% 150%`,
                        sm: `100% 150%`,
                        xs: `280% 120%`,
                    },
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: `center top`,
                }}
            >
                <Box
                    sx={{
                        height: "100vh",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: {
                            md: `rgba(255, 255, 255, 0.1)`,
                            sm: `rgba(255, 255, 255, 0.1)`,
                            xm: `rgba(255, 255, 255, 0.1)`,
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            opacity: 0.05,
                            zIndex: 10,
                            top: 150,
                        }}
                    >
                        <Avatar
                            src={logo}
                            variant="square"
                            sx={{
                                height: { md: 400, sm: 400, xs: 300 },
                                width: { md: 400, sm: 400, xs: "100%" },
                                img: {
                                    objectFit: "contain",
                                },
                            }}
                        />
                    </Box>
                    <SpacingContent>
                        <Box sx={{ width: "100%" }}>
                            <Grid
                                container
                                justifyContent={{
                                    md: "center",
                                    sm: "center",
                                    xs: "flex-start",
                                }}
                                sx={{ mt: { md: 0, sm: 0, xs: -15 } }}
                            >
                                <Grid item sm={5} xs={12}>
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                md: 45,
                                                sm: 45,
                                                xs: 35,
                                            },
                                            fontWeight: "bolder",
                                            textAlign: "center",
                                            lineHeight: 1.2,
                                            textTransform: "uppercase",
                                            background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                            backgroundClip: "text",
                                            textFillColor: "transparent",
                                        }}
                                    >
                                        For me to live is Christ
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <FormatQuoteOutlined
                                            sx={{
                                                mr: 1,
                                                color: colors.primary,
                                                fontSize: 15,
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: 22,
                                                fontWeight: "bolder",
                                                textAlign: "center",
                                                mt: 2,
                                                fontStyle: "italic",
                                                color: colors.primary,
                                            }}
                                        >
                                            Phil 1:21{" "}
                                        </Typography>
                                        <FormatQuoteOutlined
                                            sx={{
                                                ml: 2,
                                                color: colors.primary,
                                                fontSize: 15,
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                {/* <Grid item sm={5}></Grid> */}
                            </Grid>
                            <Box
                                sx={{
                                    width: "100%",
                                    borderTopLeftRadius: 25,
                                    borderTopRightRadius: 25,
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: `rgba(255,255,255,0.5)`,
                                    backdropFilter: `blur(5px)`,
                                }}
                            >
                                <Box></Box>
                                <Typography
                                    sx={{
                                        textAlign: "center",
                                        fontSize: 14,
                                        lineHeight: 1.5,
                                        background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                        backgroundClip: "text",
                                        textFillColor: "transparent",
                                        fontWeight: "bold",
                                        p: { md: 5, sm: 5, xs: 2 },
                                        width: {
                                            md: "75%",
                                            sm: "75%",
                                            xs: "85%",
                                        },
                                    }}
                                >
                                    The purpose of St. Paul's Senior Seminary,
                                    Kipalapala is the formation of Priests.
                                    Therefore, life in the seminary and its
                                    organizations are focused on this ministry.
                                    It is a life of study, prayer and service.
                                </Typography>
                                <Box></Box>
                            </Box>
                        </Box>
                    </SpacingContent>
                </Box>
            </Box>
        </>
    );
};

export default Banner;
