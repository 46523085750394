import React from "react";
import Banner from "../components/home/Banner";
import AboutUs from "../components/home/AboutUs";
import MissionAndVission from "../components/home/MissionAndVission";
import Accademics from "../components/home/Accademics";
import SeminaryLife from "../components/home/SeminaryLife";
import ContactUs from "../components/home/ContactUs";
import NewsAndEvents from "../components/home/NewsAndEvents";

const HomePage = () => {
    return (
        <>
            {/* ############# Banner ############## */}
            <Banner />

            {/* ############## AboutUs ############# */}
            <AboutUs />

            {/* ############## MissionAndVission ############# */}
            <MissionAndVission />

            {/* ############## SeminaryLife ############# */}
            <SeminaryLife />

            {/* ############## Accademics ############# */}
            <Accademics />

            {/* ############## NewsAndEvents ############# */}
            {/* <NewsAndEvents /> */}

            {/* ############## ContactUs ############# */}
            <ContactUs />
        </>
    );
};

export default HomePage;
