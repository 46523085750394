import React from "react";
import SpacingContent from "../SpacingContent";
import Section from "../Section";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";

const MissionAndVission = () => {
    return (
        <div>
            <SpacingContent maxSpace={11}>
                <Section>
                    <Box
                        sx={{
                            ".container": {
                                position: "relative",
                                borderRadius: 5,
                                p: 2,
                                pt: 6,
                                px: 4,
                                height: "100%",
                                border: `1px dotted ${colors.bgColor5}`,
                                bgcolor: "#fff",
                                boxShadow: `0 0 10px 5px${colors.bgColor1}`,
                            },
                            ".title-con": {
                                height: 50,
                                position: "absolute",
                                top: -50,
                                left: "20%",
                                right: "20%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 5,
                                py: 5,
                                bgcolor: "#fff",
                                borderTop: `2px dotted ${colors.bgColor5}`,
                            },
                            ".title": {
                                fontSize: 16,
                                textTransform: "uppercase",
                                textAlign: "center",
                                fontWeight: "bold",
                                color: colors.primary,
                                background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                backgroundClip: "text",
                                textFillColor: "transparent",
                            },
                            ".content": {
                                fontSize: 14,
                                textAlign: "center",
                            },
                        }}
                    >
                        <Grid container spacing={4} rowSpacing={15}>
                            <Grid item sm={6}>
                                <Box className="container">
                                    <Box className="title-con">
                                        <Typography className="title">
                                            Kipalapala Mission
                                        </Typography>
                                    </Box>
                                    <Typography className="content">
                                        The mission of St. Paul Major Seminary,
                                        Kipalapala is to impart academic,
                                        religious, moral and human training to
                                        young persons who will serve the
                                        Catholic Church and the nation as
                                        committed priests.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6}>
                                <Box className="container">
                                    <Box className="title-con">
                                        <Typography className="title">
                                            Kipalapala Vission
                                        </Typography>
                                    </Box>
                                    <Typography className="content">
                                        The vision of St. Paul's Major Seminary
                                        is a deep evangelization of the local
                                        Catholic Church of Tanzania through a
                                        well trained personnel. This
                                        evangelization leads to an ultimate
                                        vision of the Church that is salvation
                                        of souls.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Section>
            </SpacingContent>
        </div>
    );
};

export default MissionAndVission;
