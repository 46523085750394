import React from "react";
import PageLayout from "../layouts/PageLayout";
import { colors } from "../../assets/utils/colors";
import SpacingContent from "../../components/SpacingContent";
import Section from "../../components/Section";
import { Avatar, Box, Grid, Typography } from "@mui/material";

// => Images
import g1 from "../../assets/media/images/gallery/g8.jpg";
import g2 from "../../assets/media/images/gallery/g1.jpg";
import g3 from "../../assets/media/images/gallery/g3.JPG";
import img1 from "../../assets/media/images/gallery/g4.jpg";

const AccademicPage = () => {
    const grading = [
        {
            id: 1,
            remark: "Distinction",
            grade: "A",
            range: "96.00 - 100 Summa Cum Laude",
        },
        {
            id: 2,
            remark: "Very Good",
            grade: "B",
            range: "90.00 - 95.99 Magna Cum Laude Probatus",
        },
        {
            id: 3,
            remark: "Good",
            grade: "C",
            range: "80.00 - 89.99 Cum Laude Probatus",
        },
        {
            id: 4,
            remark: "Fair",
            grade: "D",
            range: "70.00 - 79.99 Bene Probatus",
        },
        {
            id: 5,
            remark: "Pass",
            grade: "E",
            range: "60.00 - 69.99 Probatus",
        },
        {
            id: 6,
            remark: "Failure",
            grade: "F",
            range: "0.00 - 59.99 Non Probatus",
        },
    ];
    return (
        <>
            <PageLayout
                pageHead={"Accademics"}
                pageSubHead={
                    "The primary mission of St. Paul's Senior Seminary in Kipalapala is to provide formation for priests.🙏✝️"
                }
            >
                <SpacingContent maxSpace={11}>
                    <Section>
                        <Grid container spacing={5}>
                            <Grid
                                item
                                sm={6}
                                sx={{
                                    ".img1, .img3, .img2": {
                                        transition: `.3s`,
                                    },
                                    ":hover": {
                                        ".img1, .img3": {
                                            top: 0,
                                            transition: `.3s`,
                                        },
                                        ".img2": {
                                            top: -50,
                                            transition: `.3s`,
                                        },
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: { md: 35, xs: 25 },
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            color: colors.primary,
                                            opacity: 0.8,
                                            textTransform: "uppercase",
                                            position: "relative",
                                            background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                            backgroundClip: "text",
                                            textFillColor: "transparent",
                                            mb: 2,
                                        }}
                                    >
                                        Bachelor of Theology
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            lineHeight: 1.6,
                                            opacity: 0.8,
                                        }}
                                    >
                                        The purpose of St. Paul's Senior
                                        Seminary is to provide opportunity for
                                        young-men called to be priests and who
                                        have finished their philosophical
                                        studies to continue with theological
                                        studies in a suitable environment for
                                        integral formation as well as offering
                                        the same opportunity of theological
                                        studies to other interested persons
                                        approved by the responsible authority.
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        width: "100%",
                                        position: "relative",
                                        display: "flex",
                                        mt: 10,
                                    }}
                                >
                                    <Box
                                        className="img1"
                                        sx={{
                                            height: 200,
                                            width: "100%",
                                            position: "relative",
                                            top: -50,
                                            borderRadius: 100,
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Avatar
                                            src={g2}
                                            variant="square"
                                            sx={{
                                                height: 200,
                                                width: `100%`,
                                                borderRadius: 5,
                                                img: {
                                                    objectFit: "cover",
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        className="img2"
                                        sx={{
                                            height: 200,
                                            width: "100%",
                                            position: "relative",
                                            borderRadius: 100,
                                            top: 0,
                                            overflow: "hidden",
                                            mx: { md: 5, sm: 5, xs: 2 },
                                        }}
                                    >
                                        <Avatar
                                            src={g1}
                                            variant="square"
                                            sx={{
                                                height: 200,
                                                width: `100%`,
                                                borderRadius: 5,
                                                img: {
                                                    objectFit: "cover",
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        className="img3"
                                        sx={{
                                            height: 200,
                                            width: "100%",
                                            position: "relative",
                                            top: -50,
                                            borderRadius: 100,
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Box>
                                            <Avatar
                                                src={g3}
                                                variant="square"
                                                sx={{
                                                    height: 200,
                                                    width: `100%`,
                                                    borderRadius: 5,
                                                    img: {
                                                        objectFit: "cover",
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                sx={{
                                    display: {
                                        md: "block",
                                        sm: "block",
                                        xs: "none",
                                    },
                                }}
                            >
                                <Box>
                                    <Avatar
                                        src={img1}
                                        variant="square"
                                        sx={{
                                            height: {
                                                md: "100%",
                                                sm: "100%",
                                                xs: 150,
                                            },
                                            width: `100%`,
                                            borderRadius: 10,
                                            img: {
                                                objectFit: "cover",
                                            },
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        lineHeight: 1.6,
                                        opacity: 0.8,
                                        mb: 4,
                                    }}
                                >
                                    The seminary wants to provide theological
                                    studies which is capable of responding to
                                    the challenge of its time, corresponds to
                                    the catholic theology as approved by the
                                    Congregation of Catholic education and the
                                    faculty of theology of Urbaniana Pontifical
                                    University and in case no contradiction
                                    exists approved also by the university
                                    authority of Tanzania. The programme
                                    consists of four years or eight semesters of
                                    class studies consisting of lectures, term
                                    papers, research works and seminars and for
                                    the candidates of priesthood a fieldwork
                                    year or pastoral year is added.
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        lineHeight: 1.6,
                                        opacity: 0.8,
                                    }}
                                >
                                    St. Paul's Senior Seminary is affiliated to
                                    the Pontifical University - Urbaniana, Rome.
                                    Each year students who qualify to sit for
                                    the Baccalaureate degree in Sacred Theology
                                    are enrolled and their names sent to Rome.
                                    Those permitted must have scored an average
                                    mark of at least 75% in the three major
                                    theological subjects namely Sacred
                                    Scripture, Dogmatic Theology and Moral
                                    Theology, taken together for 3 years of 6
                                    semesters.
                                </Typography>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Typography
                                    sx={{
                                        fontSize: 25,
                                        fontWeight: "bold",
                                        background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                        backgroundClip: "text",
                                        textFillColor: "transparent",
                                    }}
                                >
                                    The grade of evaluation:
                                </Typography>
                                <Box sx={{}}>
                                    {grading.map((grade) => (
                                        <Typography
                                            sx={{ mb: 1 }}
                                            key={grade.id}
                                        >
                                            <strong>
                                                {grade.grade}{" "}
                                                <small>({grade.remark})</small>{" "}
                                                :{" "}
                                            </strong>
                                            {grade.range}
                                        </Typography>
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Typography
                                    className="info-message"
                                    sx={{
                                        fontSize: 14,
                                        lineHeight: 1.6,
                                        opacity: 0.8,
                                        mb: 4,
                                        p: { md: 5, sm: 5, xs: 2 },
                                    }}
                                >
                                    The mode of testing the B.A. students is
                                    through written and oral examination. The
                                    B.A. examination Board (comprising the
                                    Lecturers of the 3 major disciplines and the
                                    Rector who is the “praesesExaminis”)
                                    conducts the oral examination. The candidate
                                    writes, for 5 hours on one of the 30 themes
                                    previously approved. The lecturer concerned
                                    corrects and marks the written work. The
                                    marks and the written work are sent to Rome
                                    for further confirmation.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Section>
                </SpacingContent>
            </PageLayout>
        </>
    );
};

export default AccademicPage;
