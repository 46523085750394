import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import AppLayout from "../pages/layouts/AppLayout";
// import GuestRoutes from "./GuestRoutes";
// import PrivateRoutes from "./PrivateRoutes";
import HomeLayout from "../pages/layouts/HomeLayout";
import HomePage from "../pages/HomePage";
import SeminaryLifePage from "../pages/seminaryLife/SeminaryLifePage";
import AccademicPage from "../pages/accademics/AccademicPage";
import GalleryPage from "../pages/gallery/GalleryPage";
import NewsAndEventsPage from "../pages/newsAndEvents/NewsAndEventsPage";
import AboutUsPage from "../pages/aboutUs/AboutUsPage";
import ContactUsPage from "../pages/contactUs/ContactUsPage";
import DailyReflections from "../pages/dailyReflections/DailyReflections";
import NewsAndEventDetails from "../pages/newsAndEvents/NewsAndEventDetails";
import DailyReflectionDetails from "../pages/dailyReflections/DailyReflectionDetails";

// ?############## GUEST ELEMENTS ###########
const HomePageElement = () => (
    <HomeLayout>
        <HomePage />
    </HomeLayout>
);
const AccademicElement = () => (
    <HomeLayout>
        <AccademicPage />
    </HomeLayout>
);
const SeminaryLifeElement = () => (
    <HomeLayout>
        <SeminaryLifePage />
    </HomeLayout>
);
const GalleryElement = () => (
    <HomeLayout>
        <GalleryPage />
    </HomeLayout>
);
const NewsAndEventsElement = () => (
    <HomeLayout>
        <NewsAndEventsPage />
    </HomeLayout>
);
const NewsAndEventsDetailesElement = () => (
    <HomeLayout>
        <NewsAndEventDetails />
    </HomeLayout>
);
const AboutUsElement = () => (
    <HomeLayout>
        <AboutUsPage />
    </HomeLayout>
);
const ContactUsElement = () => (
    <HomeLayout>
        <ContactUsPage />
    </HomeLayout>
);
const DailyReflectionsElement = () => (
    <HomeLayout>
        <DailyReflections />
    </HomeLayout>
);
const DailyReflectionsDetailesElement = () => (
    <HomeLayout>
        <DailyReflectionDetails />
    </HomeLayout>
);

// ?############## PROTECTED ELEMENTS ###########
const DashboardElement = () => (
    <AppLayout>
        <Dashboard />
    </AppLayout>
);

// !############## MAIN FUNC ###########
const App = () => {
    return (
        <React.Fragment>
            <Routes>
                {/* ################## GUEST ROUTES ################## */}
                {/* <Route element={<GuestRoutes />}> */}
                <Route path="/" element={<HomePageElement />} />
                <Route path="/accademics" element={<AccademicElement />} />
                <Route
                    path="/seminary-life"
                    element={<SeminaryLifeElement />}
                />
                <Route path="/gallery" element={<GalleryElement />} />
                <Route path="/articles" element={<NewsAndEventsElement />} />
                <Route
                    path="/articles/:article_id"
                    element={<NewsAndEventsDetailesElement />}
                />
                <Route path="/about-us" element={<AboutUsElement />} />
                <Route path="/contact-us" element={<ContactUsElement />} />
                <Route
                    path="/daily-reflections"
                    element={<DailyReflectionsElement />}
                />
                <Route
                    path="/daily-reflections/:reflection_id"
                    element={<DailyReflectionsDetailesElement />}
                />
                {/* </Route> */}

                {/* ################## PROTECTED ROUTES ################## */}
                {/* <Route element={<PrivateRoutes />}>
                    <Route path="/dashboard" element={<DashboardElement />} />
                </Route> */}
            </Routes>
        </React.Fragment>
    );
};

export default App;
