import React from "react";
import { Box, Grid } from "@mui/material";
import Loader from "react-js-loader";
import { colors } from "../assets/utils/colors";

const PageLoader = () => {
    return (
        <>
            <Box
                sx={{
                    height: "80vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Loader
                    type="bubble-ping"
                    bgColor={colors.primary}
                    color={colors.primary}
                    size={100}
                />
            </Box>
        </>
    );
};

export default PageLoader;
