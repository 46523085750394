import { Avatar, Box, Button } from "@mui/material";
import React from "react";
import paul from "../assets/media/images/paul.png";
import { ArrowRightAlt } from "@mui/icons-material";
import { colors } from "../assets/utils/colors";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const FixedPaul = () => {
    const location = useLocation();

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    // p: 5,
                    borderRadius: 5,
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 999,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Avatar
                    src={paul}
                    variant="square"
                    sx={{
                        height: { md: 70, sm: 70, xs: 60 },
                        width: { md: 50, sm: 50, xs: 40 },
                        img: {
                            objectFit: "cover",
                        },
                    }}
                />
                {location.pathname !== "/daily-reflections" && (
                    <NavLink to={"/daily-reflections"}>
                        <Button
                            variant="contained"
                            endIcon={<ArrowRightAlt />}
                            sx={{
                                borderRadius: `25px !important`,
                                fontWeight: "bold",
                                fontSize: 12,
                                boxShadow: `0px 0px 10px gray !important`,
                                background: `linear-gradient(-45deg, ${colors.primary}, ${colors.secondary})`,
                            }}
                        >
                            Daily reflection
                        </Button>
                    </NavLink>
                )}
                <Avatar
                    src={paul}
                    variant="square"
                    sx={{
                        height: { md: 70, sm: 70, xs: 60 },
                        width: { md: 50, sm: 50, xs: 40 },
                        img: {
                            objectFit: "cover",
                        },
                    }}
                />
            </Box>
        </>
    );
};

export default FixedPaul;
