import { FacebookOutlined, Instagram, X, YouTube } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React from "react";
import { colors } from "../assets/utils/colors";

const SocialNetworks = () => {
    const socialNet = [
        {
            id: 1,
            name: "facebook",
            icon: <FacebookOutlined className="icon" />,
            url: "https://www.facebook.com/profile.php?id=61559523899446&mibextid=ZbWKwL",
        },
        {
            id: 1,
            name: "instagram",
            icon: <Instagram className="icon" />,
            url: "https://www.instagram.com/mt.paulo_kipalapala?igsh=MTY4dmdha2h0MGIzZA==",
        },
        {
            id: 1,
            name: "X",
            icon: <X className="icon" />,
            url: "",
        },
        {
            id: 1,
            name: "youtube",
            icon: <YouTube className="icon" />,
            url: "https://youtu.be/jOZKDPAbmrc?si=GICx_XR-PmREOEE2",
        },
    ];
    return (
        <>
            <Box sx={{ display: "flex", zIndex: 999 }}>
                {socialNet.map((net, index) => (
                    <Box
                        key={index}
                        sx={{
                            mx: 1,
                            ".icon": {
                                transition: `.3s`,
                            },
                            ":hover": {
                                ".icon": {
                                    transition: `.3s`,
                                    transform: `scale(1.4)`,
                                },
                            },
                        }}
                    >
                        <a
                            href={net.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <IconButton
                                color={
                                    net.name === "facebook"
                                        ? "info"
                                        : net.name === "X"
                                        ? "default"
                                        : net.name === "instagram"
                                        ? "error"
                                        : net.name === "youtube"
                                        ? "error"
                                        : "default"
                                }
                                sx={{
                                    borderRadius: `50% !important`,
                                    border: `1px solid ${colors.bgColor4}`,
                                    background: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                }}
                            >
                                {net.icon}
                            </IconButton>
                        </a>
                    </Box>
                ))}
            </Box>
        </>
    );
};

export default SocialNetworks;
