import { Avatar, Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../../assets/utils/colors";
import { DoubleArrow } from "@mui/icons-material";

const SeminaryLifeWidget = ({ life }) => {
    return (
        <>
            <Link to={"/seminary-life"}>
                <Box
                    sx={{
                        borderRadius: 10,
                        overflow: "hidden",
                        height: "100%",
                        ".name": {
                            transition: `.3s`,
                            fontSize: 16,
                            textAlign: "center",
                            fontWeight: "bold",
                            mb: 2,
                            opacity: 0.8,
                            background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                            backgroundClip: "text",
                            textFillColor: "transparent",
                        },
                        ".icon": {
                            fontSize: 25,
                            color: colors.primary,
                            opacity: 0.6,
                            transition: `.3s`,
                        },
                        transition: `.3s`,
                        ":hover": {
                            transition: `.3s`,
                            boxShadow: `0 0 10px 5px${colors.bgColor1}`,
                            ".name": {
                                color: colors.primary,
                                transition: `.3s`,
                            },
                            ".icon": {
                                color: colors.orange,
                                transition: `.3s`,
                            },
                        },
                        border: `1px solid ${colors.bgColor1}`,
                        borderBottom: `2px dotted ${colors.primary}`,
                        boxShadow: `0 0 5px 0px${colors.bgColor1}`,
                    }}
                    >
                    <Box sx={{ position: "relative" }}>
                        <Box sx={{ p: 2 }}>
                            <Avatar
                                src={life.img}
                                variant="square"
                                sx={{
                                    height: 200,
                                    width: `100%`,
                                    img: {
                                        objectFit: "cover",
                                        borderRadius: 5,
                                    },
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                height: 50,
                                width: 60,
                                position: "absolute",
                                let: 0,
                                bottom: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderTopRightRadius: 10,
                                bgcolor: "#fff",
                            }}
                        >
                            {life.icon}
                        </Box>
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <Typography className="name">{life.name}</Typography>
                        <Typography sx={{ fontSize: 13, px: 2, color: "gray" }}>
                            {life.desc}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <IconButton
                            size="small"
                            color="primary"
                            sx={{
                                mr: 5,
                                mb: 2,
                                opacity: 0.5,
                            }}
                        >
                            <DoubleArrow />
                        </IconButton>
                    </Box>
                </Box>
            </Link>
        </>
    );
};

export default SeminaryLifeWidget;
