import React, { useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import AppRoutes from "./routes/App.routes";
import { isEmpty } from "lodash";
import useTheme from "./hooks/useTheme";

// !#################### MAIN FUNC ##################
const App = () => {
    // ######### CONST #############
    const theme = useTheme();

    // ######### Main return #########
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppRoutes />
            </ThemeProvider>
        </React.Fragment>
    );
};

export default App;
