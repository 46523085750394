import React from "react";
import { Grid } from "@mui/material";

const SpacingContent = ({ maxSpace, children }) => {
    return (
        <>
            <Grid container justifyContent={"center"}>
                <Grid item sm={11} xs={maxSpace ? maxSpace : 12}>
                    {children}
                </Grid>
            </Grid>
        </>
    );
};

export default SpacingContent;
