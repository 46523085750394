import { systemConfigSelector } from "../state/features/selectors";
import { useSelector } from "react-redux";

const lightColor = {
    primary: "#d00000",
    secondary: "#faa307",
    tertiary: "#3f37c9",
    bgColor1: "#f5eef0",
    bgColor2: "#fff7f7",
    bgColor3: "#ffe4e4",
    bgColor4: "#ffd0d0",
    bgColor5: "#ffbcbc",
    orange: "#fca311",
    green: "#00EE76",
    red: "#F43249",
    gray: "#D9D9D9",
    blue: "#027BBD",
};

const darkColor = {
    primary: "#d00000",
    secondary: "#faa307",
    tertiary: "#3f37c9",
    bgColor1: "#f5eef0",
    bgColor2: "#fff7f7",
    bgColor3: "#ffe4e4",
    bgColor4: "#ffd0d0",
    bgColor5: "#ffbcbc",
    orange: "#fca311",
    green: "#00EE76",
    red: "#F43249",
    gray: "#D9D9D9",
    blue: "#027BBD",
};

const useColor = () => {
    // ######### Inner states #############
    let color = null;

    // ######### Redux states #############
    const { darkMode } = useSelector(systemConfigSelector);

    if (darkMode) {
        color = darkColor;
    } else {
        color = lightColor;
    }
    return color;
};

export const colors = lightColor;
export default useColor;
