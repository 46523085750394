import React from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ErrorMessage, useField } from "formik";

const CustomInput = styled(TextField)({});

const CustomTextField = ({
    label,
    type,
    value,
    prepend,
    bBottom,
    ...props
}) => {
    const [field, meta] = useField(props);
    return (
        <>
            <Box sx={{ marginTop: 2, borderRadius: 1.5, overflow: "hidden" }}>
                <CustomInput
                    error={meta.touched && meta.error ? true : false}
                    label={label}
                    variant="filled"
                    fullWidth
                    type={type}
                    placeholder={field.label}
                    {...field}
                    {...props}
                    size="small"
                    focused={type === "date" || type === "time" ? true : false}
                    InputProps={{
                        startAdornment: prepend && (
                            <InputAdornment position="start">
                                {prepend}
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        borderBottom: `1px solid ${bBottom}`,
                    }}
                />
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomTextField;
