import { Home } from "@mui/icons-material";

export const navLinks = [
    {
        id: 1,
        title: "Home",
        icon: <Home className="icon" />,
        url: "/",
    },
    {
        id: 2,
        title: "Academics",
        url: "/accademics",
    },
    {
        id: 3,
        title: "Seminary life",
        url: "/seminary-life",
    },
    {
        id: 4,
        title: "Gallery",
        url: "/gallery",
    },
    {
        id: 5,
        title: "Articles",
        url: "/articles",
    },
    {
        id: 6,
        title: "About Us",
        url: "/about-us",
    },
    {
        id: 7,
        title: "Contact Us",
        url: "/contact-us",
    },
];
