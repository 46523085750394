import { configureStore } from "@reduxjs/toolkit";
import { RTKmiddleware } from "./RTKmiddlewares";

// => SLICE reduces
import systemConfigReducer from "../features/systemConfig/systemConfigSlice";
import gallariesReducer from "../features/gallaries/gallariesSlice";
import articlesReducer from "../features/articles/articlesSlice";
import reflectionsReducer from "../features/dailyReflection/dailyReflectionSlice";

// => RTK reducers
import { gallaryAPI } from "../../api/gallaryAPI/gallaryAPI";
import { articlesAPI } from "../../api/articlesAPI/articlesAPI";
import { reflectionsAPI } from "../../api/reflectionsAPI/reflectionsAPI";

export const store = configureStore({
    reducer: {
        [gallaryAPI.reducerPath]: gallaryAPI.reducer,
        [articlesAPI.reducerPath]: articlesAPI.reducer,
        [reflectionsAPI.reducerPath]: reflectionsAPI.reducer,
        gallariesReducer,
        articlesReducer,
        reflectionsReducer,
        systemConfigReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(RTKmiddleware),
});
