// => Get RTK responce data
export const getRTKResponseData = (data) => {
    return data.data;
};

// => Get RTK success message
export const getRTKSuccessMessage = (data) => {
    return data.message;
};

// => Get RTK error message
export const getRTKErrorMessage = (data) => {
    return data.data.message;
};
