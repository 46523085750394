import { articlesAPI } from "../../api/articlesAPI/articlesAPI";
import { gallaryAPI } from "../../api/gallaryAPI/gallaryAPI";
import { reflectionsAPI } from "../../api/reflectionsAPI/reflectionsAPI";

// => RTK middleware
export const RTKmiddleware = [
    gallaryAPI.middleware,
    articlesAPI.middleware,
    reflectionsAPI.middleware,
];
