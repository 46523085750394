import React from "react";
import PageLayout from "../layouts/PageLayout";
import Section from "../../components/Section";
import SpacingContent from "../../components/SpacingContent";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { EmailOutlined, LockClock, PhoneAndroid, PinDrop } from "@mui/icons-material";
import SendEmail from "../../components/SendEmail";

const ContactUsPage = () => {
    const ourContacts = [
        {
            id: 1,
            icon: <PinDrop className="icon" />,
            name: "Address",
            contact1: "St. Paul's Senior Seminary, Kipalapala",
            contact2: "P.O. BOX 325, TABORA",
            contact3: "Tanzania,  East Africa",
        },
        {
            id: 1,
            icon: <PhoneAndroid className="icon" />,
            name: "Call Us",
            contact1: "Tel: +255 732 988 533",
            contact2: "Tel / Fax: +255 732 988 046",
            contact3: "Mob: +255 782 456 690",
            contact4: "Mob: +255 768 498 866",
        },
        {
            id: 1,
            icon: <EmailOutlined className="icon" />,
            name: "Email Us",
            contact1: "stpaulsem@yahoo.com",
        },
        {
            id: 1,
            icon: <LockClock className="icon" />,
            name: "Open hours",
            contact1: "MON 08 : 00 AM - FRI 06 : 00 PM",
        },
    ];

    return (
        <PageLayout
            pageHead={"Contact Us"}
            pageSubHead={
                "If you have any questions or need assistance, feel free to reach out. We're here to help! 🙏✝️"
            }
        >
            <SpacingContent maxSpace={11}>
                <Section>
                    <Grid container spacing={5}>
                        <Grid item sm={6}>
                            <Grid container spacing={{ md: 4, sm: 4, xs: 2 }}>
                                {ourContacts.map((contact, index) => (
                                    <Grid item sm={6} xs={12} key={index}>
                                        <Box
                                            sx={{
                                                height: "100%",
                                                width: "100%",
                                                borderRadius: 5,
                                                position: "relative",
                                                overflow: "hidden",
                                                p: 4,
                                                ".icon": {
                                                    fontSize: 35,
                                                    color: colors.primary,
                                                    opacity: 0.8,
                                                },
                                                boxShadow: `0 0 10px 5px${colors.bgColor1}`,
                                            }}
                                        >
                                            <Box sx={{}}>
                                                {contact.icon}
                                                <Typography
                                                    sx={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {contact.name}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: 14,
                                                        mt: 1,
                                                    }}
                                                >
                                                    {contact.contact1}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: 14,
                                                        mt: 1,
                                                    }}
                                                >
                                                    {contact.contact2}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: 14,
                                                        mt: 1,
                                                    }}
                                                >
                                                    {contact.contact3}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: 14,
                                                        mt: 1,
                                                    }}
                                                >
                                                    {contact.contact4}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item sm={6}>
                            <Box>
                                <SendEmail />
                            </Box>
                        </Grid>
                    </Grid>
                </Section>
            </SpacingContent>
        </PageLayout>
    );
};

export default ContactUsPage;
