import { ArrowCircleRightOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";

const AppButton = ({ title }) => {
    return (
        <>
            <Button
                variant="contained"
                endIcon={<ArrowCircleRightOutlined />}
                sx={{
                    py: 1,
                    px: 2,
                    mt: 4,
                    borderRadius: `50px !important`,
                    bgcolor: colors.tertiary,
                }}
            >
                {title}
            </Button>
        </>
    );
};

export default AppButton;
