import * as React from "react";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import HotToastfy from "../../components/HotToastfy";
// import HomeSideBar from "../../components/HomeSideBar";
import HomeTopBar from "../../components/HomeTopBar";
import FixedPaul from "../../components/FixedPaul";
import Footer from "../../components/Footer";
import HomeSideBar from "../../components/HomeSideBar";
import useRTK from "../../hooks/useRTK";
// import Footer from "../../components/Footer";

const HomeLayout = ({ children }) => {
    // ############# Const ##############
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { pathname } = useLocation();
    const RTK = useRTK();

    // ############# FUNC ##############
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // ############## RTK #################
    RTK.useRTKMainData();

    return (
        <Box
            sx={{
                display: "flex",
                // position: "absolute",
                top: 0,
                bottom: "100%",
                right: 0,
                left: 0,
            }}
        >
            <HotToastfy />
            {/* ############### topbar ############### */}
            <HomeTopBar
                handleDrawerToggle={handleDrawerToggle}
                mobileOpen={mobileOpen}
            />

            {/* ############### sidebar ###############*/}
            <HomeSideBar mobileOpen={mobileOpen} />

            {/* ############### content ###############*/}
            <Box component="main" sx={{ flexGrow: 1 }}>
                {/* <DrawerHeader /> */}
                {children}
                <FixedPaul />
                {/* ############### Footer ############### */}
                <Footer />
            </Box>
        </Box>
    );
};

export default HomeLayout;
