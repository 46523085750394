import React from "react";
import SpacingContent from "../SpacingContent";
import Section from "../Section";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { Link } from "react-router-dom";

// => Images
import g1 from "../../assets/media/images/gallery/g8.jpg";
import g2 from "../../assets/media/images/gallery/g1.jpg";
import g3 from "../../assets/media/images/gallery/g3.JPG";
import img1 from "../../assets/media/images/gallery/g4.jpg";
import AppButton from "../buttons/AppButton";

const Accademics = () => {
    return (
        <>
            <SpacingContent maxSpace={11}>
                <Section>
                    <Box>
                        <Grid container spacing={5}>
                            <Grid
                                item
                                sm={6}
                                sx={{
                                    ".img1, .img3, .img2": {
                                        transition: `.3s`,
                                    },
                                    ":hover": {
                                        ".img1, .img3": {
                                            top: 0,
                                            transition: `.3s`,
                                        },
                                        ".img2": {
                                            top: -50,
                                            transition: `.3s`,
                                        },
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: { md: 35, xs: 25 },
                                            fontWeight: "bold",
                                            textAlign: "center",
                                            color: colors.primary,
                                            opacity: 0.8,
                                            textTransform: "uppercase",
                                            position: "relative",
                                            background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                            backgroundClip: "text",
                                            textFillColor: "transparent",
                                            mb: 2,
                                        }}
                                    >
                                        Bachelor of Theology
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            lineHeight: 1.6,
                                            opacity: 0.8,
                                        }}
                                    >
                                        The purpose of St. Paul's Senior
                                        Seminary is to provide opportunity for
                                        young-men called to be priests and who
                                        have finished their philosophical
                                        studies to continue with theological
                                        studies in a suitable environment for
                                        integral formation as well as offering
                                        the same opportunity of theological
                                        studies to other interested persons
                                        approved by the responsible authority.
                                    </Typography>
                                    <Link to={"/accademics"}>
                                        <AppButton title={"Learn more"} />
                                    </Link>
                                </Box>
                                <Box
                                    sx={{
                                        width: "100%",
                                        position: "relative",
                                        display: "flex",
                                        mt: 10,
                                    }}
                                >
                                    <Box
                                        className="img1"
                                        sx={{
                                            height: 200,
                                            width: "100%",
                                            position: "relative",
                                            top: -50,
                                            borderRadius: 100,
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Avatar
                                            src={g2}
                                            variant="square"
                                            sx={{
                                                height: 200,
                                                width: `100%`,
                                                borderRadius: 5,
                                                img: {
                                                    objectFit: "cover",
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        className="img2"
                                        sx={{
                                            height: 200,
                                            width: "100%",
                                            position: "relative",
                                            borderRadius: 100,
                                            top: 0,
                                            overflow: "hidden",
                                            mx: { md: 5, sm: 5, xs: 2 },
                                        }}
                                    >
                                        <Avatar
                                            src={g1}
                                            variant="square"
                                            sx={{
                                                height: 200,
                                                width: `100%`,
                                                borderRadius: 5,
                                                img: {
                                                    objectFit: "cover",
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        className="img3"
                                        sx={{
                                            height: 200,
                                            width: "100%",
                                            position: "relative",
                                            top: -50,
                                            borderRadius: 100,
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Box>
                                            <Avatar
                                                src={g3}
                                                variant="square"
                                                sx={{
                                                    height: 200,
                                                    width: `100%`,
                                                    borderRadius: 5,
                                                    img: {
                                                        objectFit: "cover",
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                sx={{
                                    display: {
                                        md: "block",
                                        sm: "block",
                                        xs: "none",
                                    },
                                }}
                            >
                                <Box>
                                    <Avatar
                                        src={img1}
                                        variant="square"
                                        sx={{
                                            height: {
                                                md: "100%",
                                                sm: "100%",
                                                xs: 150,
                                            },
                                            width: `100%`,
                                            borderRadius: 10,
                                            img: {
                                                objectFit: "cover",
                                            },
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Section>
            </SpacingContent>
        </>
    );
};

export default Accademics;
