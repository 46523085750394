import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Send } from "@mui/icons-material";
import { Form, Formik } from "formik";
import CustomTextField from "../components/forms/CustomTextField";
import CustomMultlineTextField from "../components/forms/CustomMultlineTextField";
import CustomSubmitButton from "../components/forms/CustomSubmitButton";
import * as Yup from "yup";
import { colors } from "../assets/utils/colors";

// ############# sendEmailValidationSchema ###################
const sendEmailValidationSchema = Yup.object({
    name: Yup.string().required(),
    email: Yup.string().required(),
    subject: Yup.string().required(),
    message: Yup.string().required(),
});

const SendEmail = () => {
    // ############## CONST ##############
    // const RTK = useRTK();

    // ############## RTK ##################
    // const [
    //     sendMessage,
    //     { isLoading, isSuccess, isError, error, data: emailData },
    // ] = useSendUsEmailMutation();
    // => Response
    // RTK.useRTKResponse(isSuccess, isError, error, emailData);

    return (
        <div>
            <Box
                sx={{
                    borderRadius: 10,
                    width: "100%",
                    overflow: "hidden",
                    boxShadow: `0 0 10px 5px${colors.bgColor1}`,
                }}
            >
                <Box sx={{ width: "100%", mt: 1, pb: 5 }}>
                    <Typography
                        sx={{
                            fontSize: { md: 30, sm: 30, xs: 25 },
                            fontWeight: "bold",
                            p: 2,
                            px: { md: 4, xs: 2 },
                            color: colors.primary,
                            opacity: 0.8,
                        }}
                    >
                        Leave us a message
                    </Typography>
                    <Box sx={{ px: { md: 4, xs: 2 } }}>
                        <Formik
                            initialValues={{
                                name: "",
                                email: "",
                                subject: "",
                                message: "",
                            }}
                            validationSchema={sendEmailValidationSchema}
                            onSubmit={(payload, { resetForm }) => {
                                // sendMessage(payload);
                                resetForm();
                            }}
                        >
                            {(formik) => (
                                <Form>
                                    <Grid container columnSpacing={2}>
                                        <Grid item sm={6} xs={6}>
                                            <CustomTextField
                                                name="name"
                                                type={"text"}
                                                label={"Full name"}
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={6}>
                                            <CustomTextField
                                                name="email"
                                                type={"email"}
                                                label={"Your email"}
                                            />
                                        </Grid>
                                        <Grid item sm={12} xs={12}>
                                            <CustomTextField
                                                name="subject"
                                                type={"text"}
                                                label={"Subject"}
                                            />
                                        </Grid>
                                        <Grid item sm={12} xs={12}>
                                            <CustomMultlineTextField
                                                name="message"
                                                type={"text"}
                                                label={"Message"}
                                                rows={3}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            sm={12}
                                            xs={12}
                                            sx={{
                                                mt: 2,
                                            }}
                                        >
                                            <CustomSubmitButton
                                                endIcon={<Send />}
                                                btnColor={"primary"}
                                                sx={{
                                                    py: 1.5,
                                                    bgcolor: colors.tertiary,
                                                }}
                                                onClick={formik.handleSubmit}
                                                // loading={isLoading}
                                            >
                                                Send message
                                            </CustomSubmitButton>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default SendEmail;
