import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { articlesEndpoints } from "./articlesEndpoints";

export const articlesAPI = createApi({
    reducerPath: "articlesAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL }),
    endpoints: (builder) => ({
        getPublicArticles: builder.query({
            query: () => `${articlesEndpoints.GET_ACTIVE_ARTICLES}`,
        }),
        getSingleArticle: builder.query({
            query: (payload) => `${articlesEndpoints.GET_BY_ID}/${payload}`,
        }),
    }),
});

export const {
    useGetPublicArticlesQuery,
    useGetSingleArticleQuery,
    useLazyGetPublicArticlesQuery,
} = articlesAPI;
